.loader {
  width : 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  color: #03045e;
  left: -100px;
  animation: shadowRolling 2s linear infinite;
}

@keyframes shadowRolling {
  0% {  box-shadow: 0px 0 rgba(#03045e, 0), 
                    0px 0 rgba(#03045e, 0), 
                    0px 0 rgba(#03045e, 0), 
                    0px 0 rgba(#03045e, 0); 
  }
  12% {  box-shadow: 100px 0 rgba(#03045e, 1),
                      0px 0 rgba(#03045e, 0),
                      0px 0 rgba(#03045e, 0), 
                      0px 0 rgba(#03045e, 0); 
  }
  25% {  box-shadow: 110px 0 rgba(#03045e, 1), 
                      100px 0 rgba(#03045e, 1), 
                      0px 0 rgba(#03045e, 0), 
                      0px 0 rgba(#03045e, 0); 
  }
  36% {  box-shadow: 120px 0 rgba(#03045e, 1), 
                      110px 0 rgba(#03045e, 1), 
                      100px 0 rgba(#03045e, 1), 
                      0px 0 rgba(#03045e, 0); 
  }
  50% {  box-shadow: 130px 0 rgba(#03045e, 1), 
                      120px 0 rgba(#03045e, 1), 
                      110px 0 rgba(#03045e, 1), 
                      100px 0 rgba(#03045e, 1); 
  }
  62% {  box-shadow: 200px 0 rgba(#03045e, 0), 
                      130px 0 rgba(#03045e, 1), 
                      120px 0 rgba(#03045e, 1), 
                      110px 0 rgba(#03045e, 1); 
  }
  75% {  box-shadow: 200px 0 rgba(#03045e, 0), 
                      200px 0 rgba(#03045e, 0), 
                      130px 0 rgba(#03045e, 1), 
                      120px 0 rgba(#03045e, 1); 
  }
  87% {  box-shadow: 200px 0 rgba(#03045e, 0),
                      200px 0 rgba(#03045e, 0), 
                      200px 0 rgba(#03045e, 0), 
                      130px 0 rgba(#03045e, 1); 
  }
  100% {  box-shadow: 200px 0 rgba(#03045e, 0), 
                      200px 0 rgba(#03045e, 0), 
                      200px 0 rgba(#03045e, 0), 
                      200px 0 rgba(#03045e, 0); 
  }
  
}




