
/* FOR LOCAL  */
 .css-pvszrq-MuiButtonBase-root-MuiIconButton-root {
    transform: rotate(90deg) !important;
}

.css-7rwes5-MuiTableCell-root {
    display: none !important;
}

.css-17npdfb-MuiTableCell-root{
    display: none !important;
}

.css-1utrezg-MuiTableCell-root{
    display: none !important;
}

.css-1kb4auo-MuiTableCell-root{
    display: none !important;
}

 .css-1088au5-MuiTableCell-root{
    display: none !important;
}

.css-8x64ye-MuiTableCell-root{
    display: none !important;
} 

.css-1dstyzn-MuiTableCell-root {
    display: none !important;
}

.css-1csduzw-MuiTableCell-root {
    display: none !important;
}

/* FOR TEST ENV */
.css-13ddshk {
    transform: rotate(90deg) !important;
}

.css-17xg23s{
    display: none !important;
}

.css-1v4nywh{
    display: none !important;
}

.css-1gws1yl{
    display: none !important;
}

.css-13sws4m{
    display: none !important;
}

.css-1nyznh5 {
    display: none !important;
}

.css-ef2kln {
    display: none !important;
}

.css-13snfga {
    display: none !important;
}

.css-1va6qt1 {
    display: none !important;
}

.css-1myfg1m {
    display: none !important;
}

.css-1epprdq {
    display: none !important;
}

.css-1wll77x {
    display: none !important;
}

.css-1psjpkr {
    display: none !important;
}

.css-70qvj9 {
  font-family: "Inter", sans-serif !important;
}

.css-vmwfk{
  font-family: "Inter", sans-serif !important;
}

.css-1ujsas3 {
  font-family: "Inter", sans-serif !important;
}

.css-198d61m-MuiTypography-root{
  font-family: "Inter", sans-serif !important;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled {
  font-family: "Inter", sans-serif !important;
}

.css-1jaw3da .MuiFormControlLabel-label.Mui-disabled {
  font-family: "Inter", sans-serif !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root {
    font-family: "Inter", sans-serif !important;
}

.css-929hxt-MuiInputBase-input-MuiInput-input{
    font-family: "Inter", sans-serif !important;
}

.css-5ka3a4{
    font-family: "Inter", sans-serif !important;
}