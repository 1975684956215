// 
// breadcrumb.scss
//


// Breadcrumb item arrow
.breadcrumb-item {
    > a {
        color: $main-color;
    }
    +.breadcrumb-item {
        &::before {
            font-family: "Material Design Icons";
            font-size: 15px;
            line-height: 20px;
            color: $main-color;
        }
    }
}
  